<template>
  <div id="STAGE_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" :title="customTitle != null? customTitle: 'Edit Assigned Stage'"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ customLabel != null? customLabel : $t(`badge.stage`) }}</label>
        <button class="btn-action" @click="stageSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="stages">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="stageBadgeRemove(index)"
              :text="`${item.name}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <StageSelectorModal v-if="stageSelectorShow" :multiple="multiple" :show.sync="stageSelectorShow" @cancel="stageSelectorCancel" @ok="stageSelectorOk" :tagFilter="tagFilter"/>
    
  </div>
</template>

<script>
import { objectClone } from '@/helpers'

export default {
  name: 'StageBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    StageSelectorModal: () => import('@/components/modal/StageSelectorModal'),
    
  },
  props: {
    show: { type: Boolean, required: true }
    , stageList: { type: Array, default: () => [] }
    , customTitle: { type: String, default: null }
    , customLabel: { type: String, default: null }
    , multiple: { type: Boolean, default: false }
    , tagFilter : { type: Object, default: () => {} }
  },
  data() {
    return {
      modalShow: false
      , stageSelectorShow: false
      , stages: []
    }
  },
  created() {
    this.modifiedList = []
  },
  beforeMount() {
    this.stages.splice(0, this.stages.length, ...this.stageList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.stages.splice(0, this.stages.length, ...this.stageList)
        }
      }
    }
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , stageSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.stageSelectorShow = false
    }
    , stageBadgeRemove: function(index) {
      this.stages.splice(index,1)
    }
    , stageSelectorToggle() {
      this.stageSelectorShow = true
    }
    , stageSelectorOk({ details }) {
      if (!this.multiple) {
        this.stages.splice(0, this.stages.length)
      }
      this.stages.push( ...details.map(i => { return { uuId: i.uuId, name: i.name }}) )
    }
    , ok() {
      this.$emit('ok', objectClone(this.stages))
      this.$emit('update:show', false)
    }
  }
}
</script>